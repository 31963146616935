.account{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.account-verify{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 20px 0;
}
.account-verify section a{
    display: flex;
    gap: 0 20px;
}


/*  */
.account-changePassword{
    background-color: white;
    width: 50%;
    padding: 50px 0;
}
.account-changePassword h1{
    text-align: center;
    color: grey;
}
.account-changePassword form{
    margin: auto;
    width: 90%;
}

@media screen and (max-width: 758px) {
    .account-changePassword{
        width: 90%;
    }
}