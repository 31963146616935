main{
    transition: .3s;
}
.mainToLeft{
    /* transform: translateX(250px); */
    width: calc(100% - 250px);
    margin-left: auto;
}

.upBtn,.deleteBtn,.viewBtn,.activateBtn,.disactivateBtn{
    color: white;
    border-radius: 10px;
    margin: 2px auto;
}
.upBtn{
    background-color: var(--color-primary);
}
.deleteBtn{
background-color: red;
}
.viewBtn{
    background-color: rgb(226, 118, 10);
}
.activateBtn{
    background-color: green;
}
.disactivateBtn{
    background-color: #333;
}
/*  */
.loader{
    display: flex;
    justify-content: center;
    min-height: 100Vh;
    align-items: center;
}
/*  */
.langSelected{
    background-color: var(--color-primary);
}

.page404{
    width: 90%;
    margin: 50px auto;
    text-align: center;
}
.page404 section{
    text-align: center;
}
.page404 img{
    height: 80vh;
}
.page404 a{
    margin-top: 10px;
    display: block;
}

.formSegmentFile{
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    width: 100%;
}
.formSegmentFile input{
    width: 100%;
}


.fullWidthBtn{
    width: 100%;
    margin: 10px 0;
}
.fullWidthBtn button{
    width: 100%;
}

/*  */
@media screen and (max-width: 768px){
    .mainToLeft{
        transform: translateX(200px);
        width: 100% !important;
        margin: auto;
    }
}