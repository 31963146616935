.modal{
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalBody{
background-color: white;
border-radius: 4px;
width: 400px;
max-width: 90%;
padding: 60px 0 40px 0;
position: relative;
max-height: 90%;
overflow-y: auto;
}
.mb-closer{
position: absolute;
right: 20px;
font-size: 30px;
top: 20px;
cursor: pointer;
}
.mb-content{
width: 90%;
margin: auto;
}

.confirmationModal section{
    display: flex;
    justify-content: center;
    gap: 0 10px;
}
.confirmationModal > svg{
    font-size: 50px;
    color: #FFDD15;
}
.confirmationModal section button{
    min-width: 45%;
    color: white;
    border-radius: 5px;
}
.confirmationModal section button:first-child{
    background-color: rgba(229, 29, 29, 0.919);
}
.confirmationModal section button:last-child{
    background-color: var(--color-primary);
}