:root{
    --inputBorder: #858C94;
}
form{
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    width: 50%;
    margin: auto;
}
input{
    padding: 12px 16px;
    border: 1px solid var(--inputBorder);
    border-radius: 8px;
    transition: .3s;
}
input:focus{
    outline: 0;
    border-color:  var(--background1);
    color: var(--background1);
}
input:focus+svg{
    color:  var(--background1) !important;
}
/* Input errror and error text style */
.isError{
    border-color: red !important;
    color: red !important;
}
.isError+svg{
    color: red !important;
}
.isError:focus+svg{
    color: red !important;
}
.isErrorText{
    color: red;
    padding: 5px 0;
    font-size: 12px;
}
/*  */
.formField,.formFieldPwd,.formFieldSearch{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.formField section,.formFieldPwd section,.formFieldSearch section{
    position: relative;
    display: flex;
}
.formField input,.formFieldPwd  input{
    width: 100% !important;
}
.formField label,.formFieldPwd  label{
    margin-bottom: 10px;
}
.formField section svg,.formFieldPwd section svg,.formFieldSearch section svg{
    color: var(--inputBorder);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s;
    font-size: 20px;

}
.formFieldPwd section svg{
    cursor: pointer;
    right: 10px;
}
.formFieldSearch section svg{
    /* right: 10px; */
    font-size: 30px;
}
.formFieldSearch input{

}
.formFieldSearch{
    width: 100%;
}
.formFieldSearch input{
    padding-left: 45px;
    width: 100%;
    border: 0;
}


.btnDisable button{
    background-color: #eee !important;
    color: grey !important;
}
.formBtn{
    width: 100%;
}
.formBtn button{
    transition: all .5s;
    height: 50px;
    padding: 0  15px;
    border-radius: 5px;
    text-align: center;
    color: white;
    width: 100%;
    background-color: var(--color-primary);
}