.profil{
    min-height: 100vh;
    padding: 50px 0;
    background-color: #eee;

    display: flex;
    align-items: center;
    justify-content: center;
}

.profil > section{
    width: 60%;
    background-color: white;
    border-radius: 10px;
    padding: 50px 0;
}
.profil > section h1{
    text-align: center;
    margin-bottom: 25px;
}
.profil section form{
    width: 90%;
    display: flex;
}


/*  */
@media screen and (max-width: 768px){
    .profil > section{
        width: 90%;
    }
}