.exchange {
    /* background-color: var(--color-normal); */
    padding: 50px 0;
}
.exchange>div {
    width: 95%;
    margin: auto;
}
.ex-devices {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 30px 0;
    background-color: var(--color-light);
    padding: 25px;
    border-radius: 5px;
    box-shadow: 4px 7px 20px rgba(143, 143, 143, 0.16);
}
.ex-devices label {
    cursor: pointer;
}
.ex-devices .ex-one-device {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.ex-devices .ex-one-device img {
    display: block;
    width: 4vh;
}
.ex-devices .ex-one-device p {
    font-size: small;
    white-space: nowrap; 
}
.ex-devices .ex-one-device .ex-card-crypto {
    background-color: #3E73FD;
}
.ex-devices .ex-one-device .ex-card-momo {
    background-color: #FDEA3E;
}
.ex-devices .ex-one-device .ex-card-carteM {
    background-color: #07A0C1;
}
.ex-devices .ex-one-device .ex-card-carteC {
    background-color: #F93EFD;
}

.ex-devices-content .form-content {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
}



.ex-devices-form {
    background-color: var(--color-light);
    padding: 50px;
    border-radius: 5px;
}
.ex-devices-form .transAmount {
    width: 250px;
}
.ex-devices-form input:read-only {
    background-color: #e9e9e9;
    border: none;
}
.exi-alert{
    display: flex;
    color: grey;
    align-items: center;
    gap: 0 20px;
}
.ex-devices-form .ex-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid #858C94;
}
.ex-devices-form .ex-form {
    width: 100%;
}
.ex-devices-form .ex-form form {
    width: 100%;
}
.ex-devices-form .ex-form form .div-inputs {
    display: flex;
    justify-content: flex-start;
    gap: 1%;
    flex-wrap: wrap;
}
.ex-devices-form .ex-form form .div-inputs>div {
    width: 49%;
}
.ex-devices-form .ex-form form .form-button {
    text-align: right;
    margin-right: 1%;
}
.transAmountAnNum{
    display: flex;
    gap: 0 10px;
    width: 100%;
}
@media screen and (max-width: 758px) {
    .ex-devices {
        padding: 25px 10px;
        gap: 10px 0;
    }
    .ex-devices label {
        width: 45%;
    }
    .ex-devices .ex-one-device b {
        font-size: 14px;
    }
    .ex-devices-form .ex-info {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .ex-devices-form .ex-form form .div-inputs>div {
        width: 100%;
    }
    .ex-devices-form .ex-form form .div-inputs {
        gap: 20px;
    }
    .ex-devices-form .ex-form form .form-button {
        text-align: center;
        margin-right: 0;
    }
    .ex-devices-form .transAmount {
        width: 100%;
    }
}
@media screen and (max-width: 512px){
    .ex-devices {
        flex-direction: column;
        gap: 10px 0;
    }
    .ex-devices label {
        width: 90%;
    }
}