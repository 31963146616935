.admin{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    padding: 50px 0;
}
.admin section{
    width: 100%;
    overflow-y: auto;
}