button{
    padding: 12px 24px;
    border: 0;
    cursor: pointer;
}
.btnLinks{
    padding: 12px;
    color: var(--background1);
}
.btnNoBackground{
    background-color: transparent;
    border: solid 2px;
}
.btnNoBackground1{
    color: var(--background1);
}
.btnNoBackground2{
    color: var(--background2);
}

.btnBg{
    color: white;
}
.btnBg1{
    background-color: var(--background1);
}
.btnBg2{
    background-color: var(--background2);
}

.btnBg1{
    background-color: var(--background1);
    border-color: var(--background1);
}
.btnBg2{
    background-color: var(--background2);
    border-color: var(--background2);
}


/* BtnRoundedOrNot */
.btnRounded{
    border-radius: 50px;
}
.btnSimple{
    border-radius: 4px;
}