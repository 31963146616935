table{
    width: 100%;
    text-align: center;
    border-collapse:collapse;
    border-radius: 10px;
    overflow: hidden;
    color: #6B6F7B;
    display: table;
}
thead{
    background-color: #F4F4F4;
}

thead tr th{
    padding: 20px 10px;
    color: #6B6F7B;
}

tbody tr{
    padding: 20px 0;
}
tbody tr td{
    padding: 10px 20px;
    border-bottom: 1px solid rgb(228, 227, 227);

}
@media screen and (max-width: 768px) {
    table{
       overflow-x: auto !important;
    }
}