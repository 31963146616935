.home {
    width: 100%;
}

/*-- Head Banner -------------------------------------*/
.head-banner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}
.head-banner>div{
    width: 45%;
}
.head-banner>div:nth-child(2) {
}

.head-banner .head-about {
    width: 80%;
}

.head-banner .div-btn>Button {
    margin-right: 10px;
}
.hb-left{
display: flex;
flex-direction: column;
gap: 20px 0;
}
.hb-left div{
    display: flex;
    gap: 10px;
}
.hb-left h1{
    font-size: 6vh;
}
.hb-left b{
    color: var(--color-primary);
}
.hb-left p{
    font-size: 3vh;
}
.hb-right{

}

.laptop {
    width: 90%;
    border: 2px inset gray;
    border-radius: 10px;
    background-color: black;
    margin: auto;
    padding: 20px 10px;
    position: relative;
}

.laptop:after {
    content: '';
    display: block;
    width: 120%;
    height: 18px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(gray, #636363, gray);
    border-radius: 0 0 15px 15px;
}

.laptop::before {
    content: '';
    display: block;
    position: absolute;
    top: 101%;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 8px;
    z-index: 999999999;
    background: linear-gradient(#a3a3a3, white, #a3a3a3);
    border-radius: 0 0 15px 15px;
}

.laptop .content {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 5px;
}





/*-- Taux -------------------------------------*/
.taux {
    background-color: white;
    padding: 100px;
}

.taux .taux-cards {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    background-color: var(--color-normal);
    padding: 30px;
    gap: 20px 0;
}

.taux-cards .taux-card {
    padding: 20px;
    width: 20%;
    background-color: white;
    transition: .5s;
}

.taux-cards .taux-card:hover {
    box-shadow: 3px 4px 19px rgba(98, 98, 98, 0.22);
}

.taux-cards .taux-card>div:first-child {
    display: inline-block;
}

.taux-cards .taux-card .card-count {
    font-size: large;
    font-weight: bold;
    color: var(--color-primary);
}

.taux-cards .taux-card>p {
    margin: 2px 0;
    font-size: 12px;
}

.bg-bitcoin {
    background-color: #3E73FD;
}

.bg-mtn {
    background-color: #FDEA3E;
}

.bg-carte {
    background-color: #07A0C1;
}

.bg-carte2 {
    background-color: #F93EFD;
}



/*-- How It Work -------------------------------------*/
.how {
    text-align: center;
}

.how h2 {
    background-color: var(--color-light);
    padding: 20px 0;
}

.how-title::after {
    content: '';
    display: block;
    width: 100%;
    height: 80px;
    background-color: var(--color-normal);
    border-radius: 100% 100% 0 0;
}

.how-body {
    width: 100%;
}

.how-body::after {
    content: '';
    display: block;
    width: 100%;
    height: 80px;
    background-color: var(--color-light);
    border-radius: 100% 100% 0 0;
}

.how-body .how-img {
    width: 40vw;
    height: 400px;
    border-radius: 30px;
    background-color: #1d1d1d;
    border: 3px inset gray;
    margin: auto;
    position: relative;
    top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.how-body .how-img iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
}



/*-- Devises -------------------------------------*/
.home-devises {
    background-color: white;
    padding: 50px;
}

.home-devices-title {
    text-align: center;
    margin: 50px 0;
}

.devices {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.devices .card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;
    height: 60px;
    gap: 0 20px;
}
.devices .card img{
    width: 40px;
    height: 40px;
}

/*-- Transactions -------------------------------------*/
.home-transactions {
    text-align: center;
    background-color: var(--color-primary);
    color: white;
    padding: 80px 20px;
}

.home-transactions h2 {
    margin: 8px;
    text-transform: uppercase;
}
.ht-fields{
    width: 95%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}
.ht-fields > div{
    width: 300px;
}

/*-- Gallery -------------------------------------*/
.home-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.hg-item {
    width: 50%;
    display: flex;
    position: relative;
}
.hg-item{
    width: calc(100% / 3);
}
/* .hg-item:hover > .hg-cover{
    opacity: 1;
} */
.hg-cover{
    cursor: pointer;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.52);
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    opacity: 1;
    transition: all .5s;
}
.hg-cover div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px 0;
    width: 90%;
    margin: auto auto auto auto;
}
.home-gallery>div img {
    width: 100%;
    height: 50vh;
}

/*-- FAQ -------------------------------------*/
.home-faq {
    padding: 80px;
    background-color: var(--color-light);

}

.home-faq>h2 {
    text-align: center;
    margin-bottom: 50px;
}

.faqs {
    border-radius: 10px;
    width: 70%;
    margin: auto;
    padding: 50px;
    background-color: var(--color-normal);
}

.faq .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 0.5px solid #BEBEBE;
    padding: 20px 0;
    transition: all .5s;
}
.question-active{
    color: var(--color-primary);
    border-bottom-color: var(--color-primary) !important;
}
.question-answer {
    color: #1d1d1d;
    max-height: 0;
    opacity: 0;
    overflow-y: hidden;
    transition: all .5s;
}
.qa-visible{
    max-height: 1000px;
    overflow:  initial;
    opacity: 1;
    padding: 20px 0;
}
@media screen and (max-width: 350px){
    .home-gallery>div img {
        height: 55vh !important;
    }
}
@media screen and (max-width: 758px) and (min-width: 512px) {
    .hg-item{
        width: 50%;
    }
    .hg-item:last-child{
        width: 100%;
    }
}
@media screen and (max-width: 512px){
    .hg-item{
        width: 100%;
    }
}



@media screen and (max-width: 758px) {
    /* Banner */
    .head-banner{
        padding: 50px 0;
       gap: 20px 0;
    }
    .head-banner>div{
        width: 85%;
    }
    .hb-left h1{
        font-size: 4vh;
    }
    .hb-left p{
        font-size: 2vh;
    }

    /* TAux */
    .taux {
        padding: 5% 0;
    }

    .taux-cards {
        flex-direction: column;
        padding: 5px;
        background-color: #07A0C1;
        gap: 20px 0;
    }
    .taux-cards .taux-card {
        width: 100% !important;
    }

    /* how it work */
    .how-body .how-img {
        width: 85%;
    }

    .how-body .how-img iframe {
    }
    /* Gallery */
    .home-gallery>div img {
        height: 35vh;
    }
    /*  */
    /*  FAq */
    .home-faq {
        padding: 10%;
    }
    .faqs {
        width: 100%;
        padding: 5%;
    }
}

@media screen and (max-width: 1000px) {
    .taux-cards .taux-card {
        width: 40%;
    }
}