:root{
    --inputBorder: #858C94;
}

.formFieldSelect{
width: 100%;
position: relative;
}
.formFieldSelect label{
    display: block;
    margin-bottom: 10px;
}
.ffs-selecter{
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    border-radius: 8px;
    transition: .3s;
    border: 1px solid var(--inputBorder);
    cursor: pointer;
    background-color: white;
    color: var(--inputBorder);
}
.ffss-currentSelect{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ffs-options{
    border-radius: 10px;
    top: 105%;
    width: 100%;
    z-index: 99999999999999999;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid var(--inputBorder);
    overflow: hidden;
}
.ffs-options span{
    color: gray;
    padding: 12px 10px;
    width: 100%;
    cursor: pointer;
    text-align: left;
}
.ffs-options span:hover{
    background-color: #eee;
}