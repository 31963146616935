/* Home head */
.dash-home{
    padding: 50px 0;
}
.dh-head{
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.dhh-left{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}
/* Left banner */
.dhhl-banner{
    padding: 20px;
    background-color: var(--background1);
    border-radius: 10px;
    color: white;
    position: relative;
}
.dhhl-banner > a{
    display: block;
margin-top: 10px;
}
.dhhlb-text{
    max-width: 65%;
}
.dhhlb-img{
    position: absolute;
    right: 0px;
    margin-left: auto;
    bottom: -20px;
    display: flex;
}
.dhhlb-img img{
    width: 100%;
}
.dhhl-chart{
    margin: 25px auto;
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}
.dhhl-chart > section{
    height: 300px;

    position: relative;
    display: flex;
    align-items: center;
}
.dhhlc-titles{
    display: flex;
    flex-wrap: wrap;
    gap: 20px 0;
    justify-content: space-between;
    margin-bottom: 20px;
}
.dhhl-chart p{
    display: flex;
    align-items: center;
    gap: 0 20px;
}
.dhhl-chart p svg{
    color: var(--background1);
    font-size: 35px;
}
.dhhl-chart div{
    display: flex;
    gap: 0 20px;
}
.dhhl-chart div p{
    display: flex;
    gap: 0 10px;
}
.dhhl-chart div span{
    height: 20px;
    width: 20px;
    display: flex;
}
/*  */
/* Right chart */

.cdh-totalExchangeChartHorizontal > section{
width: 100%;
height: 30vh;
font-size: 10px;
}

.dhh-right{
    width: 25%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
}
.dhh-right > section:first-child{
    width: 90%;
    margin: auto;
}
.dvt-results{
    margin-top: auto;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 80%;
    /* background-color: aqua; */
}
.dvtr1{
    width: 75%;
    /* background-color: violet; */
}
.dvtr1-charts{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.dvtr1-charts section{
    display: flex;
        align-items: center;
        color: var(--colorTxt5);
}
.dvtr1-charts section article{
    width: 50%;
    height: 100px;
}
.dvtr2{
    width: 20%;
}


/*  */
.cdh-totalExchangeText{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.cdhte_Card{
    width: 48%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    cursor: pointer;
    transition: .5s;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.cdhte_Card:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.cdhte_Card > img{
    width: 50px;
}
.cdhte_Card b{
    color: grey;
    font-size: 12px;
}
.cdhte_Card p{
    margin-top: 10px;
display: flex;
align-items: center;
gap: 0 5px;
}
.cdhte_Card p img{
    width: 15px;
    height: 15px;
}
.cdhte_Card p span{
    font-size: 14px;
}
.transaction-setColor{
    color: var(--colorTxt3);
}
.transaction-receiveColor{
    color: var(--colorTxt4);
}
/*  */

/* Home foot */
.dh-foot{
    display: flex;
    flex-direction: column;
    gap: 50px 0;
    width: 90%;
    margin: 50px auto;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}
.dhf-head{
display: flex;
align-items: center;
justify-content: space-between;
}
.dhf-head div{
    display: flex;
    gap: 0 10px;
}
/*  */


@media screen and (max-width: 768px) {
    .dh-head{
        gap: 20px 0;
    }
    .dhhl-banner{
        width: 100%;
    }
    .dhhl-banner button{
        width: 100%;
    }
    .dhhl-banner_totalExchange > section{
        width: 100%;
    }
    .dhhl-banner_totalExchange{
    }
    .dhhlb-text{
     max-width: 70%;
    }
    .dhhlb-img{
    max-width: 50%;
    top: 20%;
    right: -5%;
    bottom: initial;
    }
    .dhh-right,.dhh-left{
        width: 100%;
    }
    
    .cdh-totalExchangeText{
        gap: 10px 0;
    }
    .cdhte_Card{
        width: 49%;
    }
    /*  */


    /* dash  foot */
    .dh-foot{
        width: 98%;
    }
    .dhf-head {
        flex-direction: column;
        gap: 10px 0;
    }
    /*  */
}