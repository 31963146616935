:root{
    --sidebarColor: #B8BED9;
}
.adminSidebar{
    width: 250px;
    position: fixed;
    height: 100%;
    left: -250px;
    top: 0;
    background-color: white;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;
    z-index: 10000;
    transition: .3s;
}
.adminSidebarVisible{
    left: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}
.adminSidebar > section{
    width: 90%;
}
.as-logo{
}
.as-logo img{
    max-width: 100%;
    }
.as-links{
    display: flex;
    gap: 10px 0;
    flex-direction: column;
    margin: 0 0;
}
.as-links a{
    padding: 15px 20px;
    display: flex;
    align-items: center;
    gap: 0 20px;
    border-radius: 10px;
    color: var(--sidebarColor);
    transition: all .5s;
}
.as-links a svg{
    width: 25px;
}
.asl-active{
    background-color: var(--background1);
    color: white !important;
}
.as-logout{
margin-top: auto;
text-align: center;
color: var(--background1);
}

@media screen and (max-width: 768px) {
    .adminSidebar{
        width: 200px;
        left: -200px;
    }
    .as-links{
        gap: 15px 0;
    }
    .as-links a{
        padding: 15px;
        gap: 0 10px;
        font-size: 12px;
    }
    .as-links a svg{
        font-size: 20px;
    }
    .as-logout{
        margin-bottom: 15px;
    }
}
@media screen and (max-width: 320px){
    .as-logout{
        margin-bottom: 5px;
    }
}