:root {
    --color-normal: #F2F2F2;
    --color-primary: #4062FF;
    --color-danger: crimson;
    --color-light: white;
}

b {
    /* color: var(--color-primary); */
}

.visible {
    display: block !important;
}

.img-responsive {
    width: 100%;
    height: 100%;
}
.img-icon {
    float: left;
    margin-right: 5px;
}
.img-forced {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-obj {
    object-position: 100% 0px;
}


/*-- Backgrounds -------------------------------------*/
.bg-normal {
    background-color: var(--color-normal);
}

.bg-primary {
    background-color: var(--color-primary);
}

.bg-danger {
    background-color: var(--color-danger);
}

.bg-light {
    background-color: var(--color-light);
}

/*-- Texts -------------------------------------*/
.text-normal {
    color: var(--color-normal);
}

.text-primary {
    color: var(--color-primary);
}

.text-danger {
    color: var(--color-danger);
}

.text-light {
    color: var(--color-light);
}

.text-muted {
    color: gray;
}

/*-- Backgrounds -------------------------------------*/
.card {
    background-color: var(--color-light);
    box-shadow: 3px 4px 19px rgba(98, 98, 98, 0.22);
    margin: 5px;
    padding: 10px;
    border-radius: 8px;
}

.card.no-shadow {
    box-shadow: none;
}

.current {
    width: 100% !important;
    height: 100% !important;
    overflow: auto !important;
    opacity: 1 !important;
    transition: opacity 1.5s;
}