.simpleNav{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: white;
}
.sn-logo{
display: flex;
align-items: center;
}
.sn-logo img{
    /* max-width: 100px; */
    max-width: 150px;
    max-height: 80px;
}
.sn-links{
    margin-left: auto;
    display: flex;
    gap: 0 20px;
}
.sn-links a{
    color: grey;
}
.sn-actions{
    display: flex;
    margin: 0 10px;
}

/* Admin nav */
.adminNav{
    background-color: white;
    width: 100%;
    padding: 10px 25px;
    display: flex;
    align-items: center;
}
.an-search{
    width: 300px;
}
.an-langs{
margin-left: 10px;
}
.navFlagSize{
    font-size: 20px;
}
.an-notice{
margin: 0 20px;
}
.an-toggler{
    position: relative;
    color: var(--colorTxt2);
    text-align: center;
}
.an-toggler span{
    position: absolute;
    background-color: rgba(255, 8, 0, 0.822);
    color: white;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    font-size: 10px;
    right: -5px;
    top: 5px;
}
.an-toggler svg{
    font-size: 25px;
}
.an-user{
    margin-left: auto;
}
.anu-infos{

    display: flex;
    align-items: center;
    gap: 0 10px;
}
.anu-infos img{
    width: 30px;
    height: 30px;
}
.anu-infos svg{
}
.sn-menuHambuger{
    display: none;
}

@media screen and (max-width: 768px) {
    .simpleNav > .sn-links ,.simpleNav > .sn-actions {
        display: none !important;
    }
    .sn-menuHambuger{
        display: block;
        position: relative;
    }
    .sn-menuHambuger > svg{
        cursor: pointer;
        font-size: 25px;
    }
    .sn-menuHambuger > div{
        position: absolute;
        top: 100%;
        right: 0;
        background-color: white;
        width: 90vw;
        z-index: 9999;
        padding: 20px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: .3s;
    }
    .snmh-showMobileNav{
        max-height: 500px !important;
        overflow: initial !important;
        opacity: 1 !important;
    }
    .sn-menuHambuger > div > .sn-links ,.sn-menuHambuger > div > .sn-actions{
        flex-direction: column;
        gap: 5px 0 !important;
        text-align: center;
    }

    /* Admin nav */
    .adminNav{
        padding: 20px 15px;
    }
    .an-search{
        display: none;
    }
    .an-langs{
       font-size: 10px;
       margin-left: auto;
        }
    .an-toggler svg{
        font-size: 20px;
    }
    .anu-infos{
        font-size: 12px;
    }
    .anu-infos img{
        width: 30px;
        height: 30px;
    }
    /*  */
}

/* @media screen and (min-width: 512px) and (max-width: 768px){

} */