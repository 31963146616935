.exchange{
    padding: 50px 0;
    min-height: 100vh;
}
.exchange .dh-foot{
    width: 100%;
}
.agregators{
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}
.agregators ul{
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
}
.agregators ul li{
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}
.agregatorActive{
}
.agregatorInactive{
    background-color: var(--color-primary);
    color: white;
}

/* Crypto */
.e-cryptos{
    
}
.ec-list{
}
.ec-list section{
    width: 100%;
    display: flex;
    overflow-x: auto;
}
.ec-list section table{
    width: 100%;
}
/*  */