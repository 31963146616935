.tabs{
width: 100%;
}
.tabsLabels{
    display: flex;
    align-items: center;
    overflow-x: auto;
}
.tl-center{
    justify-content: center;
}
.tabsLabels p{
    padding: 5px 15px;
    transition: 0.3s;
    border-bottom: solid 2px transparent;
    cursor: pointer;
    margin-bottom: 20px;
    white-space: nowrap;

}
.tabsSelected{
    color: var(--background1);
    border-color: var(--background1) !important;
}
.tabsSelected2{
    color: white;
    background-color: var(--background1);
    border-radius: 20px;
}
.tabsResult{

}