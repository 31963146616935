/* For dahsboard */
.dashboard{
    background-color: #f5f5f5;
    min-height: 100vh;
}
.noTransactions{
    text-align: center;
    display: flex;
    gap: 10px 0;
    flex-direction: column;
}
.chartHorizontalColor1{
    background-color: #A10BE8;
}
.chartHorizontalColor2{
    background-color: #05C0E9;
}
