.auth{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    min-height: 100vh;
}
.auth-full-body{
    min-height: 85vh;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.auth-body{
    width: 90%;
    min-height: 85vh;
    display: flex;
    flex-wrap: wrap;
}
.ab-banner{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    color: white;
    gap: 20px;
}
.ab-titleAndform{
    width: 60%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px 0;
    padding: 50px 0;
    flex-direction: column;
}
.ab-titleAndform h2{
    text-align: center;
}
.formReqError{
    color: red;
}
.formReqSuccess{
    color: green;
}
.abtaf-form{
    width: 90%;
    display: flex;
    gap: 20px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.abtaf-form > div,.abtaf-form form,.abtaf-form > a{
    width: 80%;
}
.abtaf-form > a button{
    width: 100%;
}
.abtaf-otherAuth{
    display: flex;
    justify-content: center;
    gap: 0 20px;
    padding-bottom: 20px;
    border-bottom: solid 2px var(--color-primary);
}
.abtaf-form form{
    /* background-color: palegreen; */
}

.googleAuthPart{
     display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.googleAuthPart img{
    width: 80px;
    margin: auto;
}


@media screen and (max-width: 758px){
    .auth-body{
        width: 100%;
    }
    .ab-banner{
        width: 100%;
        height: 40vh;
    }
    .ab-titleAndform{
        width: 100%;
    }

    .abtaf-form{
        width: 100%;
    }
}