.dropdown{
    position: relative;
    /* background-color: aquamarine; */
}
.d-toggler{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--colorTxt2);
}
.d-toggler svg{
    font-size: 25px;
}
.d-childs{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px 0;
    top: 100%;
    position: absolute;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    width: 100%;
    text-align: center;
    z-index: 10000;
}
.d-childs a,.d-childs span,.d-childs p{
    color: var(--colorTxt2);
    cursor: pointer;
    font-size: 14px !important;
}


@media screen and (max-width: 768px) {
    .d-childs a{
        font-size: 12px;
    }
}