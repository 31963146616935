*{
    box-sizing: border-box;
}
:root{
    --background1: #4062FF;
    --background2: #0080FF;

    --colorTxt2: #919EAB;
    --colorTxt3: #FF9142;
    --colorTxt4: #5D5FEF;
    --colorTxt5: #848B91;
    --inputBorder: #858C94;


    --status-color-pending: #F09B38;
    --status-color-validated: #19BB46;
    --status-color-aborted: red;

}
@font-face {
    font-family: "LexendDeca-Bold";
    src: url("../fonts/LexendDeca-Bold.ttf");
}
@font-face {
    font-family: "LexendDeca-Regular";
    src: url("../fonts/LexendDeca-Regular.ttf");
}
@font-face {
    font-family: "LexendDeca-Medium";
    src: url("../fonts/LexendDeca-Medium.ttf");
}
html,body{
    margin: 0;
    padding: 0;
    font-family: "LexendDeca-Regular";
    overflow-x: hidden;
}
input,a,button{
    font-family: "LexendDeca-Regular";
}
a{
    text-decoration: none;
}
img{
    object-fit: cover;
}
h1,h2,h3,h4,h5,p{
    margin: 0;
    padding: 0;
}
ul,li{
    margin: 0;
    padding: 0;
    list-style: none;
}
h1,h2{
}
.chooseNewStatus{
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}
.chooseNewStatus section{
    display: flex;
    gap: 0 20px;
}
.chooseNewStatus section button{
    border-radius: 10px;
}
.transactions-ist-search-and-filter{
}
.tlsf-sf{
    margin-top: 15px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-around;
}
.tlsf-sf > input ,.tlsf-sf > div{
width: 45%;
}
.tlsf-table{
    width: 100%;
    overflow-x: auto;
}
.customDataList{
    display: flex;
    gap: 20px 0;
    justify-content: space-between;
    flex-wrap: wrap;
}
.customDataList li{
    display: flex;
    flex-direction: column;
    width: 45%;
    /* background-color: palevioletred; */
}
.customDataList span{
    color: var(--colorTxt5);
}
.customDataList img{
    width: 100%;
    max-height: 300px;
    margin-top: 40px;
}
.cdl-full{
    width: 100% !important;
}
.customDataList b{
    word-break: break-all;
}

.politic,.termsAndCondition,.legal_mention{
    width: 90%;
    margin: auto;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    color: grey;
}
.politic h1,.termsAndCondition h1,.legal_mention h1{
    color: var(--color-primary);
}
.politic a,.termsAndCondition a,.legal_mention a{
    color: var(--colorTxt3);
}
.politic h2,.termsAndCondition h2,.legal_mention h2{
    color: var(--background2);
}
.politic p,.termsAndCondition p,.legal_mention p{

}
.disconectBtn{
    display: flex;
    gap: 0 10px;
    align-items: center;
    cursor: pointer;
    color: #FF9142;
}
.accountNotActivated{
    /* margin: auto 0 50px auto; */
    margin: auto;
    background-color: #848B91;
    color: white;
    text-align: center;
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px 0;
    padding: 20px 0;
    border-radius: 10px;
    justify-content: center;
}

@media screen and (max-width: 512px) {
    .tlsf-sf{
        flex-direction: column;
        gap: 10px 0;
    }
    .tlsf-sf > input ,.tlsf-sf > div{
        width: 100%;
        }
}