footer{
    width: 100%;
    background-color: var(--color-primary);
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}
footer > section{
display: flex;
flex-direction: column;
gap: 20px 0;
color: white;
}
footer img{
    width: 80px;
}
footer > h4{
    width: 90%;
    margin: 50px auto 20px auto;
    text-align: center;
    color: white;
}
footer  h3{
    color: white;
    font-size: 25px;
}
.footer-fastLinks{
    max-width: 30%;
}
.footer-fastLinks div{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap:20px;
}
.footer-fastLinks a{
    display: flex;
    width: 45%;
    align-items: center;
    color: white;
}
.footer-socials{
text-align: center;
}
.footer-socials div{
    display: flex;
    justify-content: center;
    gap: 0 10px;
}
.footer-socials svg{
    font-size: 40px;
    color: white;
}



@media screen and (max-width: 758px) {
    footer{
        gap: 40px 0;
    }
    footer > h4{
        margin: 10px auto 20px auto;
    }
    footer > section{
       width: 90%;
        }
        .footer-fastLinks{
            max-width: initial;
            max-width: 90%;
        }
}